import * as React from "react"
import Navbar from "../../components/Navbar";
import Helmet from "react-helmet";
import { StaticImage } from "gatsby-plugin-image"
import TitleSection from "../../components/TitleSection";
import "./afspraak-reminders.scss";
import ButtonLink from "../../components/ButtonLink";
import {Link} from "gatsby";
import Footer from "../../components/Footer";

// markup
const AfspraakReminders = () => {
    return (
        <>
            <Helmet>
                <title>Careweb abonnement | Afspraak reminders</title>
                <meta name="description" content="Verstuur cliënten automatisch een sms ter herinnering van hun afspraak en verlaag het aantal no-show."/>
                <meta property="og:title" content="Careweb abonnement | Afspraak reminders"/>
                <meta property="og:url" content="https://meneerjansen-ict.nl/careweb/afspraak-reminders"/>
                <meta property="og:description" content="Afspraak reminders voor uw cliënten; een mooie service en verlaagt het aantal no-shows."/>
                <meta property="og:image" content="https://meneerjansen-ict.nl/images/logo.png"/>
                <body className="page-appointment-reminder" />
            </Helmet>

            <Navbar/>

            <main>

                <article className="bg-circle bg-circle-yellow">
                    <TitleSection title="Afspraak reminders" subtitle="Verstuur automatisch afspraak reminders via sms"/>


                    <div className="content">
                        <div className="cols">

                            <div className="col-xs-100 col-md-50 col-lg-33">
                                <div style={{marginTop: '2em'}}>
                                    <StaticImage src="../../images/pexels-mary-taylor-6009145.jpg" alt="Foto van een man die een sms ontvangt"/>
                                </div>
                            </div>

                            <div className="col-xs-100 col-md-50 col-lg-66 ">
                                <section className="text-based">
                                    <h3>Wat houdt het in?</h3>

                                    <p>
                                        Met dit abonnement is het mogelijk om cliënten automatisch een sms te sturen ter herinnering aan hun afspraak.
                                    </p>

                                </section>


                                <section className="text-based">
                                    <h3>Hoe werkt het?</h3>

                                    <p>
                                        Het versturen van afspraak reminders is per cliënt te activeren, of direct voor de gehele organisatie.
                                    </p>
                                    <p>
                                        Wanneer voor een cliënt afspraak reminders zijn geactiveerd, gaat het abonnement op zoek naar afspraken in de agenda waarbij de cliënt als aanwezig staat geregistreerd.
                                        Eén dag (of twee dagen) voor de afspraak stuurt Careweb automatisch een herinnering met het tijdstip en uw organisatienaam.
                                    </p>

                                    <p>
                                        De voordelen van afspraak reminders
                                    </p>
                                    <ul className="check">
                                        <li>Verlaag het aantal no-shows</li>
                                        <li>Een mooie service naar de cliënt</li>
                                    </ul>
                                </section>

                            </div>

                        </div>
                    </div>
                </article>

                <article>
                    <div className="content">

                        <section className="text-based" style={{marginBottom: '0'}}>
                            <div className="cols">
                                <div className="col-xs-100">
                                    <h2 className="sm-fsize-70">Abonnementen</h2>

                                    <p>
                                        Afspraak reminders zijn beschikbaar als abonnement in Careweb.
                                    </p>
                                    <p>
                                        Naast het standaard abonnement bestaat er een uitgebreid abonnement waarin meer mogelijk is.
                                    </p>
                                </div>
                            </div>
                        </section>

                        <div className="cols cols-with-margin abonnementen-cols">
                            <div className="col-md-50 prod-spec-col">
                                <h3 className="align-center">Standaard</h3>
                                <ul className="check">
                                    <li>Automatische reminders per sms</li>
                                    <li>24 of 48 uur voor de afspraak</li>
                                    <li>Aanzetten per cliënt of voor de hele organisatie</li>
                                </ul>
                                <hr/>
                                <ul className="check">
                                    <li className="cross">Inhoud sms naar keuze</li>
                                    <li className="cross">Afzender sms naar keuze <span className="li-small">(= Careweb)</span></li>
                                    <li className="cross">Bevestiging of sms is ontvangen</li>
                                </ul>
                                <hr/>
                                <section>
                                    <div className="pricing-h pricing-h2">
                                        <span className="pre-heading">
                                            Maandelijks
                                        </span>
                                        € 0,88
                                        <span className="pre-heading">
                                            per hulpverlener licentie
                                        </span>
                                    </div>
                                </section>
                                <section>
                                    <div className="pricing-h pricing-h2">
                                        <span className="pre-heading">
                                            per verstuurde sms
                                        </span>
                                        € 0,15
                                    </div>
                                </section>

                                <section className="ButtonContainer align-center">
                                    <ButtonLink label="Vraag aan" to="/contact"/>
                                </section>
                            </div>

                            <div className="col-md-50 prod-spec-col">
                                <h3 className="align-center">Uitgebreid</h3>
                                <ul className="check">
                                    <li>Automatische reminders per sms</li>
                                    <li>24 of 48 uur voor de afspraak</li>
                                    <li>Aanzetten per cliënt of voor de hele organisatie</li>
                                </ul>
                                <hr/>
                                <ul className="check">
                                    <li>Inhoud sms naar keuze <span className="li-small">(met bijv. locatie, datum/dag, telefoonnr.)</span></li>
                                    <li>Afzender sms naar keuze</li>
                                    <li>Bevestiging of sms is ontvangen</li>
                                </ul>
                                <hr/>

                                <section>
                                    <div className="pricing-h pricing-h2">
                                        <span className="pre-heading">
                                            Maandelijks
                                        </span>
                                        € 0,88
                                        <span className="pre-heading">
                                            per hulpverlener licentie
                                        </span>
                                    </div>
                                </section>
                                <section>
                                    <div className="pricing-h pricing-h2">
                                        <span className="pre-heading">
                                            per verstuurde sms *
                                        </span>
                                        vanaf € 0,19
                                    </div>
                                </section>

                                <section className="ButtonContainer align-center">
                                    <ButtonLink label="Vraag aan" to="/contact"/>
                                </section>
                            </div>

                        </div>

                        <section className="text-based">
                            <div className="cols">
                                <div className="col-xs-100">

                                    <p style={{fontSize: '10pt'}}>
                                        Prijzen zijn exclusief btw.
                                    </p>
                                    <p style={{fontSize: '10pt'}}>
                                        * De vanaf prijs is bij een afname van 500 of meer sms-jes per week. Neem voor meer informatie <Link to="/contact/">contact</Link> op.
                                    </p>
                                </div>
                            </div>
                        </section>

                    </div>
                </article>


            </main>

            <Footer />
        </>

    )
}

export default AfspraakReminders;
